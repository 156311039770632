import React from "react";
import axios from 'axios';
import { useState } from 'react';
import './eventcss.css';

const Events = () => {

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [srn, setSRN] = useState('');
	const [branch, setBranch] = useState('');
	const [m1, setM1] = useState('');
	const [srn1, setSRN1] = useState('');
	const [m2, setM2] = useState('');
	const [srn2, setSRN2] = useState('');
	const [m3, setM3] = useState('');
	const [srn3, setSRN3] = useState('');

	const handleSubmit = (e) => {

		// const obj = {
		// 	Name: name,
		// 	Email: email,
		// 	Phone: phone,
		// 	SRN: srn,
		// 	Branch: branch,
		// 	Team: team,
		// 	TeamSRN: teamSRN
		// };
		
		
		e.preventDefault();
		const obj = {
			name,
			email,
			phone,
			srn,
			branch,
			m1,
			m2,
			m3,
			srn1,
			srn2,
			srn3
		};
		//console.log(obj);
		
		
		axios.post("https://sheet.best/api/sheets/45a2ffc7-aa15-4e06-96e9-85cc27757e96",obj)
			.then((r) =>{
				console.log(r)
				
			} )
			.then((data) => {
				// The response comes here
				console.log(data);
			})
			.catch((error) => {
				// Errors are reported there
				console.log(error);
			});
		
	}
	
	return (
		// <div className="bg-black h-full py-10 pb-32 ">
		<div className="background mt-6">
		<div className="shape2"></div>
			<div className="text-center my-6 text-5xl">Details</div>
				<form>
					<input
						type="text"
						placeholder="Name"
						onChange={e => setName(e.target.value)}
					/>
					<input
						type="email"
						placeholder="Email"
						onChange={e => setEmail(e.target.value)}
					/>
					<input
						type="text"
						
						placeholder="Phone number"
						onChange={e => setPhone(e.target.value)}
					/>
					<input
						type="text"
						
						placeholder="SRN"
						onChange={e => setSRN(e.target.value)}
					/>
					<input
						type="text"
						
						placeholder="Branch"
						onChange={e => setBranch(e.target.value)}
					/>
					<input
						type="text"
						
						placeholder="Team mate names"
						onChange={e => {
							let l = e.target.value.split(',');
							//console.log(l)
							setM1(l[0])
							setM2(l[1])
							setM3(l[2])

						}
						}
					/>
					<span className="block mb-4 ml-5 opacity-50 text-sm md:inline md:ml-1 xl:ml-3">
						separate with commas
					</span>
					<input
						type="text"
						
						placeholder="Team mate SRNs"
						onChange={e => {
							let l = e.target.value.split(',');
							//console.log(l);
							setSRN1(l[0])
							setSRN2(l[1])
							setSRN3(l[2])

						}
						}
					/>
					<span className="block mb-4 ml-5 opacity-50 text-sm md:inline md:ml-1 xl:ml-3">
						seperate with commas
					</span>
					<div >
					{/* className="grid justify-items-center mr-8 md:mr-20 md:mt-4 xl:mr-40" */}
						<button onClick={handleSubmit}  >
							Register
						</button>
					</div>
				</form>
			
		 </div>
	);
};

export default Events;
