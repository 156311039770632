import React from "react";
import "./css.css";
function Home() {
	return (
		<div className="flex flex-col flex-wrap">
			 <div className="text-left font-sans flex flex-wrap justify-center pt-3 mt-20 pl-16">
		                
			 	{/* <img className="self-end w-32 h-32 top-0 right-0 absolute z-0" src="/images/Ellipse 8.png" alt="ellipse" /> */}
				{/* <img id="img1" src="/images/Final logo color.png" /> */}
				<div className="flex flex-col md:flex-row align-middle">
					<div>
						<img className="w-72 h-72" src="/images/Final logo color.png" alt="logo" />
					</div>
					<div className="mt-20 font-semibold pl-16 md:pl-3">
						<h1 className="text-4xl text-sky-50 mb-3">APPLE </h1>
						<h1 className="text-4xl text-sky-50 mb-3">DEVELOPERS' </h1>
						<h1 className="text-4xl text-sky-50 mb-3">GROUP </h1>
					</div>
				</div>
				
			

			

			{/* <header> */}
				{/* <div id="brand">
					<a href="/">PES University</a>
				</div> */}
				{/* <nav>
					<ul>
						<li>
							<a href="/home">Home</a>
						</li>
						<li>
							<a href="/events">Events</a>
						</li>
						<li>
							<a href="/about">About</a>
						</li>
						<li>
							<a href="/projects">Project</a>
						</li>

						<li>
							<a href="/domains">Domains</a>
						</li>
					</ul>
				</nav> */}
				{/* <div id="hamburger-icon"> 
				{ onClick="toggleMobileMenu(this)" }
				{ toggleMobileMenu(this) not defined. commented because it caused a warning in console }
					<div className="bar1"></div>
					<div className="bar2"></div>
					<div className="bar3"></div>
					<ul className="mobile-menu">
						<li>
							<a href="/home">Home</a>
						</li>
						<li>
							<a href="/events">Events</a>
						</li>
						<li>
							<a href="/about">About</a>
						</li>
						<li id="Projects">
							<a href="Projects">Projects</a>
						</li>
						<li id="Domains">
							<a href="Domains">Domains</a>
						</li>
					</ul>
				</div> */}
			{/* </header> */}
			</div>
		</div>
	);
}

export default Home;
