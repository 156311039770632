import React from "react";
import "./about2.css";

const About2 = () => {
  return (
    <div>
      <div className="background">
        <div className="shape"></div>
        <div className="shape"></div>
        <div className="form">
            <h2>About Us</h2>
            <p>ADG is a student organisation built for and by enterprising individuals itching to use their skills for
innovation. We platform students with myriad interests and allow them to work with like-minded
peers to bring projects to life or hone their skills in a plethora of domains ranging from Artificial
Intelligence to UI/UX. At our core, we strive to provide all the tools and guidance needed by our
members to master their trade and grow as developers.</p>
        </div>
      </div>
    </div>
  );
};

export default About2;
