import PreviousMap from "postcss/lib/previous-map";
import React from "react";
import ReactDOM from "react-dom"
import { Carousel } from "react-responsive-carousel";
//import 'bootstrap-5.1.3-dist/css/bootstrap.css'

function Card(props)
{
  return(
    /*<div class="card-group">
    <div class="card">
      <img src="..." class="card-img-top" alt="..." />
      <div class="card-body">
        <h5 class="card-title">Card title</h5>
        <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
      </div>
      <div class="card-footer">
        <small class="text-muted">Last updated 3 mins ago</small>
      </div>
    </div>
    <div class="card">
      <img src="..." class="card-img-top" alt="..."/>
      <div class="card-body">
        <h5 class="card-title">Card title</h5>
        <p class="card-text">This card has supporting text below as a natural lead-in to additional content.</p>
      </div>
      <div class="card-footer">
        <small class="text-muted">Last updated 3 mins ago</small>
      </div>
    </div>
    <div class="card">
      <img src="..." class="card-img-top" alt="..."/>
      <div class="card-body">
        <h5 class="card-title">Card title</h5>
        <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</p>
      </div>
      <div class="card-footer">
        <small class="text-muted">Last updated 3 mins ago</small>
      </div>
    </div>
  </div>*/
  <div class = "md:flex">
  <div class = "md:shrink-0">
  <div class="max-w-sm rounded overflow-hidden shadow-lg">
  <img class="w-full" src={props.pic} alt=""/>
  <div class="px-6 py-4">
    <div class="font-bold text-xl mb-2">Details1</div>
    <p class="text-gray-700 text-base">
      {props.details}
    </p>
  </div>
  <div class="px-6 pt-4 pb-2 flex ">
    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#apple</span>
    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#developers</span>
    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#group</span>
  </div>
 </div>
 </div>
 </div>
)
}
const Domains = () => {
  return(

  
  <div className ="bg-black flex flex-col w-100 md:w-100" >
  <div className="relative">
    <h1 className="bg-black flex flex-col h-3/10 text-white text-center text-5xl pt-5 pb-40 font-bold"> Domains </h1>
    <br/>
    <h2 style={{textAlign:'left',fontFamily:'sans-serif',fontSize:'25px'}}>UI/UX</h2>
    <div className="bg-black flex flex-col md:flex-row" >
    <Card pic="" details="sup " />
    <Card pic="" details="sup "/>
    <Card pic="" details="sup "/>


    </div>
    </div>
   

    <h2 class ="bg-black flex flex-col" style={{textAlign:'left',fontFamily:'sans-serif',marginTop:'100px',fontSize:'25px'}}>Software Development</h2>
    <div className="bg-black flex flex-col md:flex-row">
    <Card pic="" details="sup " />
    <Card pic="" details="sup "/>
    <Card pic="" details="sup "/>



    </div>
    
  
    <h2 class = "bg-black flex flex-col" style={{textAlign:'left',fontFamily:'sans-serif',marginTop:'100px',fontSize:'25px'}}> Machine Learning </h2>
    <div className ="bg-black flex flex-col md:flex-row" >
    <Card pic="" details="sup " />
    <Card pic="" details="sup "/>
    <Card pic="" details="sup "/>



    </div>
    <div className ="flex flex-col" style={{marginTop:'100px'}} >

    </div>
    </div>
    



    
  )
}

export default Domains;
