import React, { useState } from 'react';
import axios from 'axios';

const Contact = () => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [message, setMessage] = useState('');

	const handleSubmit = (e) => {
		e.preventDefault();

		const objt = { name, email, phone, message };

		axios
			.post(
				'https://sheet.best/api/sheets/9c2ab4c5-28fe-422b-958f-4a87b188c468',
				objt
			)
			.then((response) => {
				console.log(response);
			});
	};


	return (
		<div>
			{/* <h1>Contact</h1> */}
			<div className="items-center bg-black py-10">
				<div className="container mx-auto">
				<h1 className="my-6 text-4xl text-center text-white-700">Contact Us</h1>
					<div className="max-w-md mx-auto my-10 bg-white p-5 bg-opacity-20 rounded-3xl shadow-sm py-7">
						
						<div className="m-7">
							<form id="form">
								<div className="mb-6" >
									<input type="text" name="name" id="name" placeholder="Name" required onChange={(e) => setName(e.target.value)} className="w-full px-3 py-2 rounded-3xl bg-white bg-opacity-10 shadow-2xl placeholder:text-white placeholder:opacity-25 xl:placeholder:text-lg " />
								</div>
								<div className="mb-6">
									<input type="email" name="email" id="email" placeholder="Email" required onChange={(e) => setEmail(e.target.value)} className="w-full px-3 py-2 rounded-3xl bg-white bg-opacity-10 shadow-2xl placeholder:text-white placeholder:opacity-25 xl:placeholder:text-lg" />
								</div>
								<div className="mb-6">

									<input type="text" name="phone" id="phone" placeholder="Phone" required onChange={(e) => setPhone(e.target.value)} className="w-full px-3 py-2 rounded-3xl bg-white bg-opacity-10 shadow-2xl placeholder:text-white placeholder:opacity-25 xl:placeholder:text-lg" />
								</div>
								<div className="mb-6">

									<textarea rows="5" name="message" id="message" placeholder="Your query here..." onChange={(e) => setMessage(e.target.value)} className="w-full px-3 py-2 rounded-3xl bg-white bg-opacity-10 shadow-2xl placeholder:text-white placeholder:opacity-25 xl:placeholder:text-lg" required></textarea>
								</div>
								<div className="mb-6">
									<button type="submit" onClick={handleSubmit} className="w-full px-3 py-4 rounded-full text-white text-opacity-50 bg-white bg-opacity-10 shadow-2xl">Send</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Contact;
