import React from "react";
import "./footer.css";

const Footer = () => {
	return (
		// <div className="flex flex-col min-h-screen">
		// <footer class=" rounded-lg shadow md:flex md:items-center md:justify-between bg-black">

			<div className="flex w-10/12 h-14 mx-auto px-4 sm:px-6 lg:px-0 bg-black items-center border-t-4 rounded-full shadow-inner left-40 mt-20	footer1">

				{/* <div className="flex sticky top-[100vh] w-10/12 h-14 mx-auto px-4 sm:px-6 lg:px-0 bg-black items-center border-t-4 rounded-full shadow-inner left-40	footer1"> */}
				{/* <div className="flex fixed bottom-0 w-10/12 h-14 mx-auto px-4 sm:px-6 lg:px-0 bg-black items-center border-t-4 rounded-full shadow-inner left-40	"> */}

				<img src="/icons/adg_logo.png" alt="adg logo" className="flex flex-col w-10 ml-20 mb-2 bottom-0 mt-5" />
				<h4 className="font-bold mb-2 mt-5">PES</h4>
				<h4 className="flex justify-center w-full"><a href="/contact">Contact Us</a></h4>
				<div className="flex-col mb-2 sm:mr-5 md:mr-5 lg:mr-5">
					<a href="https://www.linkedin.com/company/apple-developers-group-pes-university/">
						<img
							src="/icons/linkedin_logo2.jpeg"
							alt="linkedin logo"
							className="flex flex-row w-5 m-1"
						></img>
					</a>

					{/* <h4 className="flex flex-col my-10 mr-10 text-lg">
				Catch us on Linkedin!
			</h4> */}
					<a href="https://instagram.com/adgpes?utm_medium=copy_link">
						<img
							src="/icons/insta_logo2.jpg"
							alt="insta logo"
							className="flex flex-row w-5 m-1"
						></img>
					</a>
				</div>
				<div className="flex-col mr-6">
					{/* <a href="https://instagram.com/adgpes?utm_medium=copy_link"> */}
					<img
						src="/icons/gmail_logo.jpg"
						alt="gmail logo"
						className="flex flex-row w-5  m-1"
					></img>
					<img
						src="/icons/github_logo.jpg"
						alt="github logo"
						className="flex flex-row w-5  m-1"
					></img>


				</div>
			</div>
		
		// </footer>
	);
};

export default Footer;
