import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import './nav.css';

function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div>
            <nav className="glassmorphism">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-0">
                    <div className="flex-shrink-0">

                        <div>
                            <img className="hidden md:block lg:block" alt="navbar logo" id="img1" src="/images/Final logo color.png" />
                        </div>
                    </div>
                    <div className="flex items-center justify-start -my-10">
                        <div>
                            <div className="hidden lg:block">
                                <div className="ml-10 flex items-baseline space-x-14">
                                    <a
                                        href="/home"
                                        className="ml-10 text-gray-200  hover:text-white px-3 py-2 rounded-md text-lg font-medium"
                                    >
                                        Home
                                    </a>

                                    <a
                                        href="/events"
                                        className="text-gray-200  hover:text-white px-3 py-2 rounded-md text-lg font-medium"
                                    >
                                        Events
                                    </a>

                                    <a
                                        href="/about"
                                        className="text-gray-200  hover:text-white px-3 py-2 rounded-md text-lg font-medium"
                                    >
                                        About
                                    </a>

                                    <a
                                        href="/projects"
                                        className="text-gray-200  hover:text-white px-3 py-2 rounded-md text-lg font-medium"
                                    >
                                        Projects
                                    </a>

                                    <a
                                        href="/domains"
                                        className="text-gray-200  hover:text-white px-3 py-2 rounded-md text-lg font-medium"
                                    >
                                        Domains
                                    </a>


                                </div>
                            </div>
                        </div>
                        <div className="-mr-2 flex lg:hidden">
                            <button
                                onClick={() => setIsOpen(!isOpen)}
                                type="button"
                                className=" inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                                aria-controls="mobile-menu"
                                aria-expanded="false"
                            >
                                <span className="sr-only">Open main menu</span>
                                {!isOpen ? (
                                    <svg
                                        className="block h-20 w-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M4 6h16M4 12h16M4 18h16"
                                        />
                                    </svg>
                                ) : (
                                    <svg
                                        className="block h-6 w-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                )}
                            </button>
                        </div>
                    </div>
                </div>

                <Transition
                    show={isOpen}
                    enter="transition ease-out duration-100 transform"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="transition ease-in duration-75 transform"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >

                    {(ref) => (
                        <div className="lg:hidden" id="mobile-menu">

                            <div ref={ref} className="w-3/4 group-hover:block z-10 px-2 pt-16 pb-3 space-y-2 sm:px-3 ">
                                <div className="bg-black">
                                    <a
                                        href="/home"
                                        className=" bg-black text-white block px-3 py-2 rounded-md text-base font-medium"
                                    >
                                        Home
                                    </a>

                                    <a
                                        href="/events"
                                        className="bg-black text-gray-300  hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                                    >
                                        Events
                                    </a>

                                    <a
                                        href="/about"
                                        className="bg-black text-gray-300  hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                                    >
                                        About
                                    </a>

                                    <a
                                        href="/projects"
                                        className="bg-black text-gray-300  hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                                    >
                                        Projects
                                    </a>

                                    <a
                                        href="/domains"
                                        className="bg-black text-gray-300  hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                                    >
                                        Domains
                                    </a>
                                </div>
                            </div>
                        </div>
                    )}
                </Transition>
            </nav>
        </div>



        // before including responsiveness
        // <div>


        // 			<div id="brand">
        // 				<img id="img1" src="/images/Final logo color.png" />
        // 			</div>
        // 		<nav>
        // 			<ul>
        // 				<li><a href="/home">Home</a></li>
        // 				<li>
        // 					<a href="/events">Events</a>
        // 				</li>
        // 				<li>
        // 					<a href="/about">About</a>
        // 				</li>
        // 				<li>
        // 					<a href="/projects">Project</a>
        // 				</li>

        // 				<li>
        // 					<a href="/domains">Domains</a>
        // 				</li>
        // 			</ul>
        // 		</nav>
        // </div>	
        // 		<div id="hamburger-icon"> 
        // 					<div className="bar1"></div>
        // 					<div className="bar2"></div>
        // 					<div className="bar3"></div>
        // 					<ul className="mobile-menu">
        // 						<li>
        // 							<a href="/home">Home</a>
        // 						</li>
        // 						<li>
        // 							<a href="/events">Events</a>
        // 						</li>
        // 						<li>
        // 							<a href="/about">About</a>
        // 						</li>
        // 						<li id="Projects">
        // 							<a href="Projects">Projects</a>
        // 						</li>
        // 						<li id="Domains">
        // 							<a href="Domains">Domains</a>
        // 						</li>
        // 					</ul>
        // 				</div>
    );
};

export default Navbar;
