import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import About from "./About";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Contact from "./Contact";
import Domains from "./Domains";
import Events from "./Events";
import Home from "./Home";
import NotFound from "./NotFound";
import Projects from "./Projects";
import About2 from "./about2";

const App = () => {
	return (

		<BrowserRouter>
		
			<div
				className="App full-height"
				style={{
					backgroundColor: "black",
					color: "white",
					height: "100vh",
				}}
			>
				<Navbar/>
				<div className="content">
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/home" element={<Navigate to="/" />} />
						<Route path="/about" element={<About2 />} />
						<Route path="/events" element={<Events />} />
						<Route path="/projects" element={<Projects />} />
						<Route path="/domains" element={<Domains />} />
						<Route path="/contact" element={<Contact />} />
						<Route path="*" element={<NotFound />} />
					</Routes>
				</div>
				<div className="fixed inset-x-0 bottom-0">
				<Footer/>
			    </div>
			</div>
		</BrowserRouter>
	);
};

export default App;
