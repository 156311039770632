import React from "react";
const Projects = () => {
	return (
		<div >
			<div>
                    <div className="flex flex-col w-100 md:w-100">
                        <div className="relative">
                            <h1 className="bg-black flex flex-col h-3/10 text-white text-center text-5xl pt-5 pb-40 font-bold ">Our projects</h1>
                            <div className="bg-white flex flex-col h-2/10 text-black pt-5 pb-20 min-h-full text-left">
                                <div className="float-left ml-5 flex-wrap w-2/5 text-lg text-justify">
                                        <b>Learn N' Fun</b><br/>
                                        Our team integrated business model canvas and design thinking into repurposing CIE courses into
                                        an app. The app adds to the interactivity of the courses, making them more enjoyable for the
                                        students</div>
                                <div className="absolute top-30 bottom-70 right-0 w-3/5 h-2/10 flex justify-end md:top-5 md:bottom-5 md:right-20 md:w-3/5 md:h-2/10 md:flex md:justify-end">
                                    <img className="w-auto h-auto object-contain" src="/images/ProjectsImg1.png" alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="relative">
                        <div className="bg-black flex flex-col h-3/10 text-white pt-10 pb-40 text-center md:pt-30 ">
                            <div className="self-center ml-5 flex-wrap w-1/2 text-lg">
                            <b>Clickathon</b><br/>
                            Another venture involved the annual techno-cultural college fest Aatmatrishta— we curated
                            Instagram stories to perfectly encompass each day of AT week and held the Clickathon, a contest
                            to capture the most memeable moments of the fest.</div>
                        </div>
                        <div className="bg-white flex flex-col h-2/10 text-black pt-5 pb-20 pr-5 text-end">
                            <div className="float-end self-end ml-5 flex-wrap w-2/5 text-lg text-justify">
                            <b>Workshops</b><br/>
                            UI/UX plays a vital role in increasing user engagement and making an application as robust as
                            possible. The ADG UI/UX team at PES university recently conducted a string of workshops aimed
                            at inculcating the core concepts of user development and interface through educated discussions
                            presentations, and exercises. Attendees were invited to learn something new each day, with the
                            first workshop entailing the basics of Figma: a browser-based UI and UX design application, with
                            excellent design, prototyping, and code-generation tools. The next session delved deep into the
                            key UX Principles, Peter Orville's Honeycomb, and served to introduce the concept of Userflow.
                            The participants were allowed to explore and choose and website of their choice, and create a
                            Userflow for it using an online collaborative whiteboard known as FigJam. Another workshop dealt
                            with prototyping, moving from one screen to the other using buttons, hover animations, etc. The
                            final workshop encouraged the attendees to brainstorm through a 'fish' thinking exercise. They
                            were each assigned a word from the exercise, for which they then had to design a user persona,
                            and a landing page for the persona. Throughout the course of the workshops, attendees were
                            evaluated, mentored and encouraged to think more creatively in their path as future designers and
                            developers</div>
                            <div className="absolute bottom-20 top-80 left-0 w-3/5 h-5/10 mt-20 flex float-left md:bottom-5 md:top-5 md:flex md:float-left">
                                <img className="w-auto h-auto object-scale-down" src="/images/ProjectsImg2.png" alt=""/>
                            </div>  
                        </div>
                        </div>
                        <div className="relative">
                        <h1 className="bg-black flex flex-col h-3/10 text-white text-center text-3xl pt-5 pb-40 font-bold">Projects - done by our core members</h1>
                        <div className="bg-white flex flex-col h-2/10 text-black pt-5 pb-20 text-left">
                            <div className="float-left ml-5 flex-wrap w-2/5 text-lg text-justify">
                            <b>Website</b><br/>
                            We created a website that showcases our Apple centric brand identity as well as our projects,
                            domains, and vision.</div>
                            <div className="absolute top-30 right-0 bottom-70 w-3/5 h-2/10 flex justify-end md:top-5 md:bottom-5 md:object-contain md:right-20 md:w-3/5 md:h-2/10 md:flex md:justify-end">
                                <img className="w-auto h-auto" src="/images/ProjectsImg1.png" alt=""/>
                            </div>
                        </div>
                        </div> 
                        <div className="relative">
                        <div className="bg-black flex flex-col h-3/10 text-white pt-5 pb-40 text-center text-lg">
                            <div className="self-center ml-5 flex-wrap w-1/2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus ab neque, ea saepe natus omnis exercitationem a, amet blanditiis autem eveniet adipisci officiis molestiae distinctio quia ipsa, harum ipsum soluta?</div>
                        </div>
                        <div className="bg-white flex flex-col h-2/10 text-black pt-5 pb-20 pr-5 text-left text-lg">
                            <div className="float-end self-end ml-5 flex-wrap w-2/5 text-justify">Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus ab neque, ea saepe natus omnis exercitationem a, amet blanditiis autem eveniet adipisci officiis molestiae distinctio quia ipsa, harum ipsum soluta?</div>
                            <div className="absolute bottom-15 top-18 left-0 w-3/5 h-2/10 flex justify-left md:bottom-5 md:top-5 md:flex md:justify-left">
                                <img className="w-auto h-auto" src="/images/ProjectsImg2.png" alt=""/>
                            </div>  
                        </div>
                        </div>
                    </div>
                    
                    </div>
		</div>
	);
};

export default Projects;
